import React, {useState,useEffect,lazy,Suspense} from 'react';
import {Loading} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import { Modal } from "react-bootstrap";
import axios from 'axios'
import swal from "sweetalert2";

const TransferOrderForm = lazy(() => import("../form/TransferOrderForm"));

const useLoad = () => {
    const t = useTranslate();
    const [customFieldsTo,setCustomFieldsTo] = useState([])
    const [section,setSection]=useState([])
  
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section"
            ).then((response) => {
                setSection(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_fields/all/to"
            ).then((response) => {
                setCustomFieldsTo(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }
                else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }
                else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
        })()
    }, [])

    return [section,customFieldsTo]
}

const SelectConvertItemsList = (props) => {
    const t = useTranslate();
    const [actionOnList,setActionOnList] = useState('')
    const [isFor,setIsFor] = useState(false)
    const [section,customFieldsTo] = useLoad()

    //Date passé à la création de TO
    let now = new Date();
    let annee   = now.getFullYear();
    let mois    = now.getMonth() + 1;
    let jour    = now.getDate();
    
    let moisFormat = (mois>9) ? mois : '0'+mois;
    let jourFormat = (jour>9) ? jour : '0'+jour;

    const selectAction = (e) => {
        setActionOnList(e.target.value)
    };

    const handleClickAction = () => {
        switch (actionOnList) {
            case 'ToCollected':
                setIsFor('ToCollected')
                break;
        }
    };

    return(<>
        <Modal show={isFor} onHide={()=>setIsFor(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{props.listeArticle.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(isFor && isFor=='ToCollected') ?
                    <Suspense fallback={<Loading></Loading>}>
                        <TransferOrderForm 
                            moisFormat={moisFormat}
                            jourFormat={jourFormat}
                            annee={annee}
                            section={section}
                            customFields={customFieldsTo}
                            module='listeArticle'
                            oSection={props.listeArticle.section_id}
                            listeArticleId={props.listeArticle.id}
                            setActionOnList={setActionOnList}
                            setIsFor={setIsFor}
                            from={props.from}
                        />
                    </Suspense>
                :
                    false
                }
            </Modal.Body>
        </Modal>
        <div className="row d-flex align-items-center">
            <div className="form-group col-9">
                <label>{t('convertThisList')+'...'}</label>
                <select value={actionOnList} className="form-control" onChange={(e)=>selectAction(e)}>
                    <option value="">-- Select --</option>
                    <option value="ToCollected">{t('convertIntoToCollected')}</option>
                </select>
            </div>
            <div className="col-3">
                <button disabled={actionOnList==''} className="btn btn-primary" onClick={()=>handleClickAction()}>{t('btnValidate')}</button>
            </div>
        </div>
    </>
    )
}

export default SelectConvertItemsList;
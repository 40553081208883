import { lazy } from "react";

const Wms = lazy(() => import("./Wms"));
const ShipmentVoucherReceipt = lazy(() => import("./ShipmentVoucherReceipt/ShipmentVoucherReceipt"));
const ShipmentVoucherMenu = lazy(() => import("./ShipmentVoucherReceipt/ShipmentVoucherMenu"));
const BoxScan = lazy(() => import("./Scan/BoxScan"));
const BoxOpenScan = lazy(() => import("./Scan/BoxOpenScan"));
const ItemsScan = lazy(() => import("./Scan/ItemsScan"));
const BoxesInfos = lazy(() => import("./Scan/BoxesInfos"));
const BinsDetails = lazy(() => import("./BinsContenu/BinsDetails"));
const ZoneDetails = lazy(() => import("./ZoneContenu/ZoneDetails"));
const StockMovement = lazy(() => import("./StockMovement/StockMovement"));
const StockMovementBins = lazy(() => import("./StockMovement/StockMovementBins"));
const StockMovementZone = lazy(() => import("./StockMovement/StockMovementZone"));
const EcomPicked = lazy(() => import("./EcomTo/EcomPicked"));
const EcomChemin = lazy(() => import("./EcomTo/EcomChemin"));
const ItemScanEcom = lazy(() => import("./EcomTo/Comp/ItemScanEcom"));
const ToPickedHome = lazy(() => import("./To/ToPickedHome"));
const ToPicked = lazy(() => import("./To/ToPicked"));
const ToScanItem = lazy(() => import("./To/Picked/ToScanItem"));
const ToReceive = lazy(() => import("./To/ToReceive"));
const ToReceiveBefore = lazy(() => import("./To/Receive/ToReceiveBefore"));
const ToScanArticle = lazy(() => import("./To/Receive/ReceiveComponents/ToScanArticle"));
const ItemDetailsWms = lazy(() => import("./ItemDetailsWms/ItemDetailsWms"));
const ParentDetailWms = lazy(() => import("./ParentDetailWms/ParentDetailWms"));
const PeripheriqueWms = lazy(() => import("./PeripheriqueWms/PeripheriqueWms"));
const InventoryWms = lazy(() => import("./InventoryWms/InventoryWms"));
const CategoryInventoryWms = lazy(() => import("./InventoryWms/CategoryInventoryWms"));
const ZoneInventoryWms = lazy(() => import("./InventoryWms/ZoneInventoryWms"));
const BinsInventoryWms = lazy(() => import("./InventoryWms/BinsInventoryWms"));
const ZoneInventoryScanWms = lazy(() => import("./InventoryWms/Comp/ZoneInventoryScanWms"));
const BinsInventoryScanWms = lazy(() => import("./InventoryWms/Comp/BinsInventoryScanWms"));
const CategoryInventoryScanWms = lazy(() => import("./InventoryWms/Comp/CategoryInventoryScanWms"));
const NotificationListWms = lazy(() => import("./Notification/NotificationListWms"));
const NotificationDetailWms = lazy(() => import("./Notification/NotificationDetailWms"));
const HistoriqueDetail = lazy(() => import("./Historique/HistoriqueDetail"));
const ReassortInterneMouvment = lazy(() => import("./ReassortInterneMouvment/ReassortInterneMouvment"));
const ReassortInterneMouvmentDetail = lazy(() => import("./ReassortInterneMouvment/ReassortInterneMouvmentDetail"));
const PurchaseOrderReceipt = lazy(() => import("./PurchaseOrderReceipt/PurchaseOrderReceipt"))
const PurchaseOrderReceiptScan = lazy(() => import("./PurchaseOrderReceipt/Comp/PurchaseOrderReceiptScan"))
const ListeArticle = lazy(() => import("./ListeArticle/ListeArticle"));
const ListeArticleDetailAdd = lazy(() => import("./ListeArticle/ListeArticleDetailAdd"));
const Test = lazy(() => import("./Test"));
const WmsReport = lazy (()=> import("./ReportPbi/WmsReport"))
const PoB2b = lazy (()=> import("./PoB2b/PoB2b"))
const PoB2bCollect = lazy (()=> import("./PoB2b/Comp/PoB2bCollect"))
const ItemScanB2b = lazy (()=> import("./PoB2b/Comp/ItemScanB2b"))
const ReturnNote = lazy (()=> import("./ReturnNote/ReturnNote"))
const ReturnNoteReceipt = lazy (()=> import("./ReturnNote/Comp/ReturnNoteReceipt"))
const CreateDeliveryNote = lazy (()=> import("./Livraison/CreateDeliveryNote"))
const DeliveryNoteInProgressList = lazy (()=> import("./Livraison/DeliveryNoteInProgressList"))
const ValidationDeliveryNote = lazy (()=> import("./Livraison/ValidationDeliveryNote"))
const DeliveryNoteAddItem = lazy (()=> import("./Livraison/DeliveryNoteAddItem/DeliveryNoteAddItem"))
const DeliveryNoteDetail = lazy (()=> import("./Livraison/ValidationComp/DeliveryNoteDetail"))

/**********!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!***************** */
/*     Toutes les routes WMS doivent commencer par wms/:wmsid            */
/*                                                                       */
/**********!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!***************** */
const wmsRoutes = [
  {
    exact:true,
    path: "/wms/:wmsid",
    component: Wms
  },
  {
    exact:true,
    path: "/wms/:wmsid/inventory",
    component: InventoryWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/inventory/categorie/:inventoryname/:zoneid/:id",
    component: CategoryInventoryWms
  },{
    exact:true,
    path: "/wms/:wmsid/inventory/categorie/:inventoryname/:zoneid/:id/:rhid",
    component: CategoryInventoryScanWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/inventory/zone/:inventoryname/:zoneid/:id",
    component: ZoneInventoryWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/inventory/zone/:inventoryname/:zoneid/:id/:zoneiventoryid/:rhid/:zoneinventoryname",
    component: ZoneInventoryScanWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/inventory/bins/:inventoryname/:zoneid/:id",
    component: BinsInventoryWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/inventory/bins/:inventoryname/:zoneid/:id/:binsinventoryid/:rhid/:binsid/:binnumber",
    component: BinsInventoryScanWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/bins-scan",
    component: BinsDetails
  },
  {
    exact:true,
    path: "/wms/:wmsid/zone-content",
    component: ZoneDetails
  },
  {
    exact:true,
    path: "/wms/:wmsid/shipment-voucher-receipt",
    component: ShipmentVoucherReceipt
  },
  {
    exact:true,
    path: "/wms/:wmsid/b2b/return-note",
    component: ReturnNote
  },
  {
    exact:true,
    path: "/wms/:wmsid/b2b/return-note/receipt/:id",
    component: ReturnNoteReceipt
  },
  {
    exact:true,
    path: "/wms/:wmsid/purchase-order-receipt",
    component: PurchaseOrderReceipt
  },
  {
    exact:true,
    path: "/wms/:wmsid/purchase-order/receipt/:id",
    component: PurchaseOrderReceiptScan
  },
  {
    exact:true,
    path: "/wms/:wmsid/b2b/purchase-order",
    component: PoB2b
  },
  {
    exact:true,
    path: "/wms/:wmsid/b2b/purchase-order/collection/:id",
    component: PoB2bCollect
  },
  {
    exact:true,
    path: "/wms/:wmsid/b2b/purchase-order/item-scan/:id/:zoneid/:zoneparam/:binsref/:zonename/",
    component: ItemScanB2b
  },
  {
    exact:true,
    path: "/wms/:wmsid/bins-movement",
    component: StockMovement
  },
  {
    exact:true,
    path: "/wms/:wmsid/bins-movement/bins/:binsid",
    component: StockMovementBins
  },
  {
    exact:true,
    path: "/wms/:wmsid/bins-movement/zone/:zoneid",
    component: StockMovementZone
  },
  {
    exact:true,
    path: "/wms/:wmsid/item-details/:articleref",
    component: ItemDetailsWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/parent-details/:articleid",
    component: ParentDetailWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/peripheral",
    component: PeripheriqueWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-order/:toid",
    component: ToPicked
  },
  {
    exact:true,
    path: "/wms/:wmsid/list-items",
    component: ListeArticle
  },
  {
    exact:true,
    path: "/wms/:wmsid/list-items-details/:listId",
    component: ListeArticleDetailAdd
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-order/:toid/:zoneid/item-scan/:zoneparam/:id/:zonename/:toref/:fsection",
    component: ToScanItem
  },
  {
    exact:true,
    path: "/wms/:wmsid/ecom-collect/:toid/:zoneid/item-scan/:zoneparam/:binsid/:zonename/:toref",
    component: ItemScanEcom
  },
  {
    exact:true,
    path: "/wms/:wmsid/ecom-collect",
    component: EcomPicked
  },
  {
    exact:true,
    path: "/wms/:wmsid/ecom-collect/:toid",
    component: EcomChemin
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-orders-collect",
    component: ToPickedHome
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-orders-receive",
    component: ToReceive
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-orders-receive/:toid/:zonename",
    component: ToReceiveBefore
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-orders-receive/:toid/:zonename/:toref/scan",
    component: ToScanArticle
  },
  {
    exact:true,
    path: "/wms/:wmsid/reassort_interne",
    component: ReassortInterneMouvment
  },
  {
    exact:true,
    path: "/wms/:wmsid/reassort_interne/:reassort_interne_id",
    component: ReassortInterneMouvmentDetail
  },
  {
    exact:true,
    path: "/wms/:wmsid/report",
    component: WmsReport
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid",
    component: ShipmentVoucherMenu
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid/boxes-scan",
    component: BoxScan
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid/items-scan",
    component: BoxOpenScan
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid/items-scan/:boxnumber",
    component: ItemsScan
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid/box-informations",
    component: BoxesInfos
  },
  {
    exact:true,
    path: "/wms/:wmsid/notifications/list",
    component: NotificationListWms
  },
  {
    exact:true,
      path: "/wms/:wmsid/notifications/detail/:id",
      component: NotificationDetailWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/historique/edit/:id",
    component: HistoriqueDetail
  },
  {
    exact:true,
    path: "/wms/:wmsid/delivery-note/create",
    component: CreateDeliveryNote
  },
  {
    exact:true,
    path: "/wms/:wmsid/delivery-note/:id/add-items",
    component: DeliveryNoteAddItem
  },
  {
    exact:true,
    path: "/wms/:wmsid/delivery-note/in-progress",
    component: DeliveryNoteInProgressList
  },
  {
    exact:true,
    path: "/wms/:wmsid/delivery-note/validation",
    component: ValidationDeliveryNote
  },
  {
    exact:true,
    path: "/wms/:wmsid/delivery-note/detail/:id",
    component: DeliveryNoteDetail
  },
];
  
  export default wmsRoutes;
import React, { useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FormatedPrice, SelectSeller, CarouselImage} from "@gull";
import { Modal,Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt, faExclamationTriangle,faExclamationCircle,faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import { promotions2Inputs,promotionsPrefixe } from 'app/allPromotions';
import { Link } from 'react-router-dom'

const DetailItems = (props) => {
    const t = useTranslate()
    const [showNote,setShowNote]=useState(false)
    const [showCarousel,setShowCarousel]=useState(false) 


    const getNote = (detailNote) =>{
        setShowNote(detailNote)
    }

    let remise = (props.value.remise == 0) ? false : <>- <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.remise} /></>

    let promoTitle = (props.value.promotion!=null) ? t(props.value.promotion.type) : (props.value.details!= null && props.value.details.type=='percent') ? t('manualDiscountPercent') : t('manualDiscountNet')
    
    let promo = ''
    if(props.value.promotion!=null){
        if(promotions2Inputs.includes(props.value.promotion.type)){
            promo = ' / '+props.value.promotion.value[0]+' '+t('for')+' '+props.value.promotion.value[1]
            
        }else if(promotionsPrefixe.includes(props.value.promotion.type)){
            if(props.value.promotion.type=='percent'){
                promo = ' / '+'-'+props.value.promotion.value+'%'
            }else{
                promo = ' / '+'-'+props.value.promotion.value
            }
        }else{
            promo = props.value.promotion.value
        }
        
    }else if(props.value.details!=null){
        promo = ' / '+props.value.details.reason
    }

    let articleUrl = (props.from=='erp') ? '/erp/article/edit/' : '/pos/'+props.posid+'/item-base/details/'

    return(
        <div>
            <Modal show={showCarousel} onHide={()=>setShowCarousel()}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.value.article && props.value.article.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.value.article && props.value.article.image) ?
                        <CarouselImage images={props.value.article.image} />
                    :
                        <div></div>
                    }
                </Modal.Body>
            </Modal>
            <Modal size="md" show={showNote} onHide={() => setShowNote(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: showNote}}>
                    </div>
                </Modal.Body>
            </Modal>
            <div className={props.style}>
                <div className="d-flex justify-content-between">
                    <div className="col-9">
                        {(props.module && props.module=='Caisse') ? 
                            <div className="text-muted">{
                                (props.value.article!=null) ? props.value.article.sku+' '+props.value.article.name : 
                                (props.value.article_retour!=null) ? props.value.article_retour.sku+' '+props.value.article_retour.name :
                                (props.value.details!=null) ? t('giftcard')+' '+props.value.details.reference : 
                                false
                            }</div> 
                        :
                            <div className="text-muted">{
                                (props.value.article!=null) ? 
                                <Link className="pointer" to={articleUrl+props.value.article.id}>{props.value.article.name}</Link> : 
                                (props.value.article_retour!=null) ? 
                                <Link className="pointer" to={articleUrl+props.value.article_retour.id}>{props.value.article_retour.name}</Link> :
                                (props.value.details!=null) ? t('giftcard')+' '+props.value.details.reference : 
                                false
                            }</div> 
                        }
                    </div>
                    <div className="col-3">
                        {(props.value.article && props.value.article.image) ?
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img className="pointer" style={{maxHeight:'50px', maxWidth:'70px'}} src={props.value.article.image.split(';')[0]} alt={props.value.article.name} onClick={()=>setShowCarousel(true)}/>
                            </div>
                        :
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img style={{maxHeight:'50px', maxWidth:'70px'}} src="/assets/images/no-image.png" alt="No image" />
                            </div>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>{'x'+props.value.quantity}</div> 
                    <div style={{width:'25%', textAlign:'end'}}><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.prix_remise} /></div>
                </div>
                <div className="d-flex justify-content-between">
                    <span className="d-flex align-items-center">
                        {(!remise) ?
                            false
                        :  
                            <>
                                <Badge bg="warning" text="dark">{promoTitle+promo+' / '}<span>{remise}</span></Badge>&nbsp;&nbsp;
                            </>
                        }
                        {(props.value.article_retour!=null && props.value.ticket_retour!=null) ?
                            (props.from=='erp') ? 
                                <Link className="text-muted pointer" to={'/erp/caisse/ticket/'+props.value.ticket_retour.id}>{props.value.ticket_retour.bar}</Link>
                            :
                                <span className="text-muted">{props.value.ticket_retour.bar}</span>
                        :
                            false
                        }
                        {(props.value.note!=null) ?
                            <>
                                <FontAwesomeIcon icon={faCommentAlt} className='pointer fs-5 text-primary' onClick={()=>getNote(props.value.note)} />
                            </>
                        :   
                            false
                        }
                    </span>
                    {(props.from=='salesOrderPos') ?
                        <span className="mx-1 px-1">
                            <input type="text" className="form-control" disabled value={(props.value.vendeur!=null) ? props.value.vendeur.name+' '+props.value.vendeur.firstname : ''} />
                        </span>
                    :
                        <span className="mx-1 px-1">
                            <SelectSeller 
                                section={props.section}
                                posid={props.posid}
                                item={props.value}
                                lineId={props.value.id}
                                caisseIdent={props.caisseIdent}
                                pos_id={props.pos_id}
                                ticketId={props.ticketId}
                                setTicketDetail={props.setTicketDetail}
                                from='erp'//à passer pour obligatoirement pour connaitre les options de son module 

                                //ces deux props sont passées uniquement depuis l'edit du salesOrder depuis l'erp
                                module={props.module}
                                commandeDetail={props.commandeDetail}
                            />
                        </span>
                    }
                </div>
                {(props.module!='Caisse' && props.value.hasOwnProperty('already_collect')) ?
                    <div>
                        {(props.value.already_collect!=0) ? <div className="fw-bold"><FontAwesomeIcon icon={faCheckCircle} className='text-success'/>&nbsp;{t('collected')+' : x'+props.value.already_collect}</div> : false }
                        {(props.value.have_to_collect!=0) ? <div className="fw-bold"><FontAwesomeIcon icon={faExclamationCircle} className='text-warning'/>&nbsp;{t('toCollectWms')+' : x'+props.value.have_to_collect}</div> : false }
                        {(props.value.en_to!=0) ? <div className="fw-bold"><FontAwesomeIcon icon={faExclamationCircle} className='text-warning'/>&nbsp;{t('enCoursTo')+' : x'+props.value.en_to}</div> : false }
                        {(props.value.reste!=0) ? <div className="fw-bold"><FontAwesomeIcon icon={faExclamationTriangle} className='text-danger'/>&nbsp;{t('necessaryActionOrder')+' : x'+props.value.reste}</div> : false }
                    </div>
                :
                    false
                }
            </div>
        </div>
    )
}

export default DetailItems;
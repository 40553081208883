import React from "react";
import {FormatedPrice} from "@gull";
import {Type} from 'react-bootstrap-table2-editor';
import Moment from 'react-moment';

const articleColumnGenerate = (articleList, columnShow, headerSortingStyle, articleEditionDatatable, optionsDataTable, ourOptionsDataTable,t,authParam) => {

    const columns = [];
    
    articleList.map(value=>{
        let activeB2c = (!authParam.company.advanceparams.hasOwnProperty('b2c_price') || authParam.company.advanceparams.b2c_price) ? true : false
        let activeB2b = (authParam.company.advanceparams.hasOwnProperty('b2b_price') && authParam.company.advanceparams.b2b_price) ? true : false
        let readAccess = authParam.access.find(access=>access.action == value.allowAccessName+'read')
        let writeAccess = authParam.access.find(access=>access.action == value.allowAccessName+'write')
        let textAppend = (!writeAccess && value.editable) ? '*' :  ''
        let priceConfig = false

        let b2cField = ['ht','price','margeN','margeP']
        let b2BField = ['price_b2b','ttcb2b','margeB2b','margePB2b']

        if(!b2cField.includes(value.dataField) && !b2BField.includes(value.dataField)){
            //Si la colonne ne concerne pas le prix b2c ou le prix b2b on ajoute la colonne 
            priceConfig=true
        }else{
            //Si la colonne concerne le prix b2c ou prix b2b, on vérifie dans les advances params si on les a activé avant de les afficher 
            if(b2cField.includes(value.dataField)){
                if(!activeB2c && !activeB2b){
                    //Si aucune configuration n'a été faite, on affiche la colone b2c par défaut
                    if(b2cField.includes(value.dataField)) priceConfig=true
                }else if(activeB2c){
                    priceConfig=true
                }
            }else if(b2BField.includes(value.dataField) && activeB2b){
                priceConfig=true
            }
        }
        
        let dataInit = {
            dataField: value.dataField,
            text: t(value.text) ? t(value.text)+textAppend : value.text+textAppend,
            hidden: (columnShow.includes(value.dataField)) ? false : true,
            sort: value.sort,
            headerSortingStyle,
        }
        //On vérifie si on l'utilisateur à un droit à la lecture de le colonne
        if(!readAccess && priceConfig){           
            if(value.editable){//Dans le cas de champ éditable, on vérifie le type d'édition "select ou input text" et si l'user est autorisé à éffectuer cette action
                if(value.editType=='input'){
                    if((!writeAccess)){
                        //Si le user est autorisé au éditer ce champ, on intègre validator pour éditer le champ en question
                        dataInit['validator'] = (newValue, row, column, done) => {
                            articleEditionDatatable(row,column.dataField,newValue,done,false,t)
                            return {
                                async: true
                            };
                        }
                    }else{
                        //Si le user n'est pas autorisé à éffectuer des modification, on envoie "editable:false"
                        dataInit['editable'] = false
                    }

                }else if(value.editType=='select'){
                    //Dans ce dataTable, haveStock à un select avec des valeurs en dure
                    let selectHaveStock={
                        type: Type.SELECT,
                        options: [{ value: t('yes'), label: t('yes')}, { value: t('no'), label: t('no') }],
                        ouroptions: { [t('yes')]: 'oui', [t('no')]: 'non' },
                    }
                    let selectEditor={
                        type: Type.SELECT,
                        options: optionsDataTable[value.dataField],
                        ouroptions: ourOptionsDataTable[value.dataField],
                    }
                    if((!writeAccess)){
                        //Si le user est autorisé au éditer ce champ, on intègre validator pour éditer le champ en question
                        dataInit['editor'] = (value.dataField=='havestock') ? selectHaveStock : selectEditor
                        dataInit['validator'] = (newValue, row, column, done) => {
                            articleEditionDatatable(row,column.dataField,column.editor.ouroptions[newValue],done,false,t)
                            return {
                                async: true
                            };
                        }
                    }else{
                        //Si le user n'est pas autorisé à éffectuer des modification, on envoie "editable:false"
                        dataInit['editable'] = false
                    }
                }
            }else{
                //Dans le cas où le champ n'est pas du tout éditable 
                dataInit['editable'] = false
            }

            if(value.formatter){//Si les datas du champs nécessite un traitement particulier 
                if(value.formatter=='price'){
                    dataInit['formatter'] = (rowData) => rowData==null || rowData==='' ? '' : (<FormatedPrice langRegion={'fr-FR'} devise={authParam.company.devise} digit={2} priceToFormat={rowData} />)
                }else if(value.formatter=='date'){
                    dataInit['formatter'] = (rowData) => rowData==null || rowData=='' ? '' : <Moment format="DD/MM/YYYY">{rowData}</Moment> 
                }else{
                    dataInit['formatter'] = (rowData) => rowData==0 || rowData==null || rowData=='' ? '' : rowData+' %'
                }
            }

            columns.push(dataInit)
        }
    })
    
    return columns;
};

export default articleColumnGenerate;
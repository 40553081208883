import React, {useState,useEffect} from 'react';
import axios from 'axios';
import swal from "sweetalert2";

const constructionDocument = async (setLoading,id,callBackConstruct,t,data,setItemsBilling,setAllInfos) => {
    if(setLoading) setLoading(true)

    const response = await axios.post(process.env.REACT_APP_API_URL+"api/facture/invoice/construction/"+id, data
    ).then((response) => {
        if(!response.data.valid){
            let interms='';
            var keys = Object.keys(response.data.errors);
            for(var i = 0; i < keys.length; i++){
                interms+=response.data.errors[keys[i]].join(',')+','
            }
            interms=interms.split(',')
            let messageError='';
            interms.forEach(interm=>messageError+=t(interm)+'. ')
            messageError=messageError.replace('undefined','');
            swal.fire(messageError, "", "error");
            if(setLoading) setLoading(false)
        }
        else{   
            if(callBackConstruct) callBackConstruct(response.data.data)
            if(setItemsBilling) setItemsBilling(response.data.data.facture_article_list)
            if(setAllInfos) setAllInfos(response.data.data)
            if(setLoading) setLoading(false)
        }
    }).catch((error) => {
        if(error=='end_session') return;
        let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
        swal.fire(t(errorName), "", "error");
        if(setLoading) setLoading(false)
    })

}

export default constructionDocument;
import React, {useState,useEffect,useContext} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {CardPerso, FacturationSelectDossier,SubmitForm} from "@gull"
import axios from 'axios';
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';
import { useHistory } from "react-router-dom";

const useLoad = () => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [company,setCompany]=useState(false)
    const [loading,setLoading]=useState(true)

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/company/"+authParam.company.id
            ).then((response) => {
                setCompany(response.data)
                setLoading(false)
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])

    return [company,loading,authParam]
};

const ConvertPoIntoInvoiceBuyForm = (props) => {
    const t = useTranslate();
    const [company,loading,authParam] = useLoad()
    const history = useHistory();

    const [apiError,setApiError] = useState(false)
    const [validButton,setValidButton] = useState(false)
    const [folderId,setFolderId] = useState(false)
    const [numeroDossier,setNumeroDossier] = useState('')
    const [companyId,setCompanyId] = useState('')

    const callbackFolderLink = (folder) => {
        setNumeroDossier(folder.nbr+' - '+folder.name)
        setFolderId(folder.id)
    };

    const handleSubmit = async () => {
        setApiError(false)
        setValidButton(true)

        let obj = {
            devise:props.allDevises[0],
        }

        if(companyId!='') obj['other_company'] = companyId
        if(folderId) obj['dossier_b2b'] = folderId

        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/po/convert_into_billing_buy/"+props.poId,obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                setApiError(messageError);
            }
            else{
                history.push("/invoice/details/"+response.data.data.id)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            setApiError(messageError);
        })
        setValidButton(false)
    };

    return(
        <>
            {(props.allDevises.length > 1) ? 
                <div className='alert alert-danger fw-bold mt-1 mb-2'>{t('convertPoBillinBuyErr')}</div>
            :
                <div className="form-group">
                    <label>{t('devise')}* :</label>
                    <input type="text" disabled className='form-control' defaultValue={props.allDevises[0]}/>
                </div>
            }
            {loading ? 
                <div className="d-flex justify-content-center"><span className="spinner-border spinner-border-sm"></span></div> 
            :
                <div className="form-group">
                    <label>{t('company')} :</label>
                    <select disabled={(props.allDevises.length > 1) || validButton} value={companyId} className='form-control' onChange={(e)=>setCompanyId(e.target.value)}>
                        <option value="">{authParam.company.name}</option>
                        {company.other_company.map(value=>{
                            return(
                                <option key={value.id} value={value.id}>{value.name}</option>
                            )
                        })}
                    </select>
                </div>
            }
            <div className="form-group">
                <label>{t('linkToDoc')} :</label>
                <CardPerso>
                    <div className='fw-bold text-center fs-5'>{numeroDossier!='' ? t('folder')+' '+numeroDossier : t('noFolderLinked')}</div>
                    <FacturationSelectDossier 
                        callbackFolderLink={callbackFolderLink}
                        size="100%"
                    />
                </CardPerso>
            </div>
            {apiError && <div className='alert alert-danger mt-2'>{apiError}</div>}
            <button 
                disabled={(props.allDevises.length > 1) || validButton} 
                className='btn btn-primary mt-3 text-end' 
                onClick={()=>handleSubmit()}
            >
                {validButton ? <span className="spinner-border spinner-border-sm"></span> : t('btnValidate')+' '+t('form').toLowerCase()}
            </button>
        </>
    )
}

export default ConvertPoIntoInvoiceBuyForm;
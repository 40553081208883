import swal from "sweetalert2";
import axios from "axios";
import https from "https"

const printTagImgFile = (peripheriqueParam,print,setPrint,qteTag) =>{

    const response = axios.get('http://localhost/portcom/printImageFile.php?printerName='+peripheriqueParam.imprimante_etiquette.host+'&fileName='+print+'.pdf&orientation=landscape&qteTag='+qteTag
    ).then((response) => {
        console.log('good')
        setPrint(false)
    }).catch((error) => {
        console.log(error)
    })
}

export const imprimeEtiquette = (peripheriqueParam,print,setPrint,qteTag,t) =>{
    
    if(peripheriqueParam && peripheriqueParam.imprimante_etiquette && peripheriqueParam.imprimante_etiquette.model=='image_file'){
        printTagImgFile(peripheriqueParam,print,setPrint,qteTag)

    }else{
        swal.fire(t('noConfiguredPeripheral'), "", "error");
    }
}
import React,{useState,useEffect,useRef} from "react";
import { FormatedPrice,SelectSeller,ControlAccess,CarouselImage} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faMinus,faEdit, faCommentAlt} from '@fortawesome/free-solid-svg-icons'
import { Badge,Modal } from "react-bootstrap";
import EditPriceModal from "./Component/EditPriceModal"
import NoteModal from "./Component/NoteModal"
import axios from 'axios'
import swal from "sweetalert2";
import { promotions2Inputs,promotionsPrefixe } from 'app/allPromotions';
import DeleteLineButton from './Component/DeleteLineButton'

const LineItemPos = (props) =>{
    const t =useTranslate()
    const [qte,setQte]=useState(props.item.quantity)
    const [showEditPrice,setShowEditPrice]=useState(false)
    const [showNote,setShowNote]=useState(false)
    const [afterRestrcitionModifPrice,setAfterRestrcitionModifPrice]=useState(false) 
    const [loading,setLoading]=useState({
        delete:false,
        total:false
    })
    const [timer,setTimer]=useState({});
    const [currentTimer,setCurrentTimer]=useState(false);
    const [disabled,setDisabled]=useState(false)
    const [showRestriction,setShowRestriction]=useState(false) 
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    const [showCarousel,setShowCarousel]=useState(false) 

    const qteRef = useRef('')

    useEffect(function () {
        setQte(props.item.quantity)

    }, [props.currentTicket,props.item])
    
    let remise = (props.item.remise == 0) ? false : <>- <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.item.remise} /></>

    let badgeTitle = (props.item.promotion!=null) ? t(props.item.promotion.type) : (props.item.details!= null && props.item.details.type=='percent') ? t('manualDiscountPercent') : (props.item.details!= null && props.item.details.type=='modif') ? t('priceModification') : t('manualDiscountNet')

    let promo = ''

    if(props.item.promotion!=null){
        if(promotions2Inputs.includes(props.item.promotion.type)){
            promo = ' / '+props.item.promotion.value[0]+' '+t('for')+' '+props.item.promotion.value[1]

        }else if(promotionsPrefixe.includes(props.item.promotion.type)){
            if(props.item.promotion.type=='percent'){
                promo = ' / '+'-'+props.item.promotion.value+'%'
            }else{
                promo = ' / '+'-'+props.item.promotion.value
            }
        }else{
            promo = props.item.promotion.value
        }

    }else if(props.item.details!=null && props.item.details=='modif'){

    }

    const changeQte = (e) =>{
        if(e.target.value==''){  
            //Permet d'autoriser la suppréssion du chiffre afin d'écrire ce qu'on veut
            setQte(e.target.value)
        }else if(+e.target.value > 0){
            //Permet de contraindre l'utilisateur à n'écrire que des chiffres
            setQte(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..{2}).*/g, '$1'))
        }

        props.setDisabledPay(true)
        props.setDisabledCloudButton(true)
    }

    function updateQteTimer (name) {

        if(currentTimer!=false){
            clearTimeout(timer[currentTimer]) 
        }

        setCurrentTimer(Date.now());

        let tempTimer = setTimeout(() => {
            if(!props.restrictionRemoveQteLineItem.go && name=='minus'){
                setShowRestriction(true)
            }else{
                submit()
            }
        }, 1000)
        setTimer({...timer,[Date.now()]:tempTimer})        
    }

    const submit = async () =>{
        setLoading({...loading,total:true})
        setDisabled(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            quantity: qteRef.current.value,
            id:props.cookies.current_ticket,
            detail_id:props.item.id
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setLoading({...loading,total:false})
                setDisabled(false)
                
                let articleAfficheur = response.data.data.ticket_detail.find(value=>value.article_id == props.item.article_id)
                
                if(props.peripheriqueParam && props.peripheriqueParam.afficheur && props.peripheriqueParam.afficheur.model=='second_screen_display'){
                    // affiche et envoyé en param 'reason' pour afficher le contenu du ticket sur l'écran secondaire, si on envoie 'clear' il néttoiera l'ensemble de l'afficheir
                    props.affichageClient(response.data.data,false,false,'add',false,false,props.peripheriqueParam)

                }else{
                    props.affichageClient(articleAfficheur.article.name,articleAfficheur.prix_remise,articleAfficheur.quantity,0,props.devise,t,props.peripheriqueParam)
                    //le 0 sur la variable 'raison' est passé pour définir que les infos ne concerne pas le total
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading({...loading,total:false})
        props.setRelanceFocus(Date.now())
        props.setDisabledCloudButton(false)
    } 

    const handleBlur = () =>{
        if(qte==''){
            //Si l'utilisateur à supprimé la quantité et n'en a pas renseigné une nouvelle et est sortie du champ
            //On remet la quantité qui avait été ranseigné 
            setQte(props.item.quantity)
        }else{
            if(!props.restrictionRemoveQteLineItem.go && (+qte < +props.item.quantity)){
                //Si on change la quantité et qu'elle est inférieur à la quantité renseigné initialement 
                //Si il y a une restriction, un vendeir ayant l'autorisation doit scanner son badge 
                setShowRestriction(true)
            }else{
                submit()
            }
        }
    }

    const handleKeyPress = (e) =>{
        if(e.key === 'Enter'){
            qteRef.current.blur()
        }
    }

    const changeQteBtn = (name) =>{
        if(name == 'plus'){
            setQte(qte+1)
            qteRef.current.value=qteRef.current.value+1

            updateQteTimer(name)
        }else{
            if(qteRef.current.value > 1){
                setQte(qte-1)
                qteRef.current.value=qteRef.current.value-1
            }
            
            updateQteTimer(name)
        }
        props.setDisabledPay(true)
        props.setDisabledCloudButton(true)
    }

    const showModal = (name) =>{
        if(name == 'price'){
            setShowEditPrice(true)
        }else{
            setShowNote(true)
        }
    }

    const callback = () =>{
        setAfterRestrcitionModifPrice(true)
    }

    const callbackRestriction = () =>{
        submit()
        setShowRestriction(false)
        props.setRestrictionRemoveQteLineItem({...props.restrictionRemoveQteLineItem,go:false})
    }

    const closeModal = (name) =>{
        if(name == 'price'){
            setShowEditPrice(false)
            if(afterRestrcitionModifPrice){ 
                props.setRestrictionModifPrix({...props.restrictionModifPrix,go:false})
                setAfterRestrcitionModifPrice(false)
            }
        }else{
            setShowNote(false)
        }
        props.setRelanceFocus(Date.now())
    }

    const closeRestricton = () =>{
        setQte(props.item.quantity)
        setShowRestriction(false)
        props.setDisabledCloudButton(false)
    }

    return(
        <>
            <Modal show={showCarousel} onHide={()=>setShowCarousel()}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.item.article && props.item.article.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.item.article && props.item.article.image) ?
                        <CarouselImage images={props.item.article.image} />
                    :
                        <div></div>
                    }
                </Modal.Body>
            </Modal>
            <Modal show={showRestriction} onHide={()=>closeRestricton()}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{marginTop:'80px'}}>
                        <ControlAccess section={props.section} focus={true} restriction={props.restrictionRemoveQteLineItem} moduleId={props.posid} setRestriction={props.setRestrictionRemoveQteLineItem} callback={callbackRestriction} />
                    </div>
                </Modal.Body>
            </Modal>
            <NoteModal 
                showNote={showNote}
                closeModal={closeModal}
                posid={props.posid}
                cookies={props.cookies}
                currentTicket={props.currentTicket}
                setCurrentTicket={props.setCurrentTicket}
                lineId={props.lineId}
                item={props.item}
                module='pos'
            />
            <EditPriceModal 
                showEditPrice={showEditPrice}
                closeModal={closeModal}
                cookies={props.cookies}
                item={props.item}
                setCurrentTicket={props.setCurrentTicket}
                setDisabledPay={props.setDisabledPay}
                section={props.section}
                posid={props.posid}
                restrictionModifPrix={props.restrictionModifPrix}
                setRestrictionModifPrix={props.setRestrictionModifPrix}
                callback={callback}
                module='pos'
                groupId={[]}
                forGroup={false}
            />
            <div className={props.style}>
                <div className="row">
                    <div className="col-8">
                        <div className="row">
                            <strong className="fsArticlePos">{props.item.article.sku+' '+props.item.article.name}</strong> 
                        </div>
                        <div className="row marginLeftArticle">
                            <div className="col-3 offset-1 m-0 p-0 input-group-sm" style={{zIndex:'999'}}>
                                <input ref={qteRef} disabled={disabled} className='form-control' value={qte} type="text" onChange={(e)=>changeQte(e)} onBlur={()=>handleBlur()} onKeyPress={(e)=>handleKeyPress(e)}/>
                            </div>
                            <div className="col-8 pl-1 m-0 pb-0 pt-1 zIndexBtnNotePos">
                                <button disabled={disabled} className="btn btn-success btn-sm sizeBtnPos" onClick={()=>changeQteBtn('plus')}><FontAwesomeIcon icon={faPlus} /></button>&nbsp;
                                <button disabled={disabled} className="btn btn-danger btn-sm sizeBtnPos" onClick={()=>changeQteBtn('minus')}><FontAwesomeIcon icon={faMinus} /></button>&nbsp;
                                <button disabled={disabled} className="btn btn-primary btn-sm sizeBtnPos" onClick={()=>showModal('price')}><FontAwesomeIcon icon={faEdit} /></button>&nbsp;
                                <button className="btn btn-primary btn-sm sizeBtnPos" onClick={()=>showModal('note')}><FontAwesomeIcon icon={faCommentAlt} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        {(props.item.article && props.item.article.image) ?
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img className="pointer" style={{maxHeight:'50px', maxWidth:'70px'}} src={props.item.article.image.split(';')[0]} alt={props.item.article.name} onClick={()=>setShowCarousel(true)}/>
                            </div>
                        :
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img style={{maxHeight:'50px', maxWidth:'70px'}} src="/assets/images/no-image.png" alt="No image" />
                            </div>
                        }
                    </div>
                </div>
                <div className="row marginLeftArticle mt-1">
                    <div className="col-4">
                        <div className="row">
                            <div className="col-9 m-0 p-0 zIndexBtnNotePos">
                                <span className="priceArticlePos">{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.item.unitaire} />}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 m-0 p-0">
                        <SelectSeller 
                            section={props.section} 
                            cookies={props.cookies}
                            item={props.item}
                            lineId={props.item.id}
                            posid={props.posid}
                            currentTicket={props.currentTicket}
                            setCurrentTicket={props.setCurrentTicket} 
                            setRelanceFocus={props.setRelanceFocus}
                            from='pos'//à passer pour obligatoirement pour connaitre les options de son module 
                        />
                    </div>
                    <div className="col-4">
                        <div className="text-end m-0 p-0 priceArticlePos totalPricePosition">
                            {(loading.total) ?
                                <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                            :
                                <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.item.prix_remise} />
                            }
                        </div>
                        <span className="m-0 p-0 crossPosition">
                            {(loading.delete) ?
                                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                                :
                                    <DeleteLineButton
                                        setLoading={setLoading}
                                        loading={loading}
                                        setDisabledPay={props.setDisabledPay}
                                        posid={props.posid}
                                        idsLocalStorage={idsLocalStorage}
                                        item={props.item}
                                        cookies={props.cookies}
                                        setCurrentTicket={props.setCurrentTicket}
                                        setRelanceFocus={props.setRelanceFocus}
                                        from='lineItem'
                                        restrictionDeleteLineItem={props.restrictionDeleteLineItem}
                                        setRestrictionDeleteLineItem={props.setRestrictionDeleteLineItem}
                                        section={props.section} 
                                    />
                            }
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {(remise) ?
                            <Badge bg="warning" text="dark">{badgeTitle+promo+' / '}<span>{remise}</span></Badge>
                        :
                            (props.item.details!=null && props.item.details.type=='modif') ? 
                                <Badge bg="warning" text="dark">{badgeTitle}</Badge>
                            :
                                false
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default LineItemPos;
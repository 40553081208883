import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle,faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const SelectPrinterErp = (props) => {
    const t = useTranslate();

    const handleSelectPrinter = (e) => {
        let param = props.company.peripherique.find(item=>item.id==e.target.value)
        props.setParamPrinter({imprimante_etiquette:param})
    };

    return(
        <div className={props.style}>
            <label>{t('selectAPrinter')}</label>
            {(props.paramPrinter) ?
                <FontAwesomeIcon icon={faCheckCircle} className='text-success positionIconFacture' />
            :
                <FontAwesomeIcon icon={faExclamationCircle} className='text-danger positionIconFacture' />
            }
            <select className='form-control' onChange={(e)=>handleSelectPrinter(e)}>
                <option value="">-- Select --</option>
                {Object.keys(props.company).length!=0 && props.company.peripherique.map(value=>{
                    if(value.type=='imprimante_etiquette'){
                        return(
                            <option key={value.id} value={value.id}>{value.name}</option>
                        )
                    }
                })}
            </select>
        </div> 
    )
}

export default SelectPrinterErp;
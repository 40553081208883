import React, { Fragment, useState, useEffect,useContext,lazy,Suspense } from "react";
import { FormatedPrice,MasterCloudCommandeButton,MasterCloudGiftCardButton,MasterCloudCreateItem,OpenCashDrawerBtn,Loading} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import { Modal,DropdownButton,Dropdown} from "react-bootstrap";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt} from '@fortawesome/free-solid-svg-icons'
import swal from "sweetalert2";
import {imprimeCaisseIdent} from 'app/imprimeEtiquette'
import { useCookies} from 'react-cookie';
import { Badge } from "react-bootstrap";
import { PeripheriqueContext } from 'app/App';
import ApercuPrixArticleBarre from './ApercuPrixArticleBarre'

const PaymentForm = lazy(() => import('../../../@gull/components/form/PaymentForm/PaymentForm'));
const ReturnArticleModal = lazy(() => import('../../../@gull/components/ReturnArticle/ReturnArticleModal'));
const CreditGiftCardModal = lazy(() => import('../../../@gull/components/modal/CreditGiftCardModal'));
const SearchSalesOrder = lazy(() => import('../../../@gull/components/SearchSalesOrder/SearchSalesOrder'));
const CreateItemFormPos = lazy(() => import('../../../@gull/components/form/CreateItemFormPos'));
const OrderFormNext = lazy(() => import('../../../@gull/components/form/PaymentForm/Component/OrderFormNext'));
const AlertCurrentReceipt = lazy(() => import('../../../@gull/components/alert/AlertCurrentReceipt'));
const MatchBarCodeForm = lazy(() => import('../../../@gull/components/form/MatchBarCodeForm'));
const SearchDevisModal = lazy(() => import('../../../@gull/components/modal/SearchDevisModal'));
const SavedTicketModal = lazy(() => import('../../../@gull/components/modal/SavedTicketModal'));

const useLoad = (idsLocalStorage,posid) => {
  const t =useTranslate()
  const [caisseIdentName,setCaisseIDentName]=useState(false)

  const [section,setSection]=useState(false)
  const [restrictionAssociate,setRestrictionAssociate]=useState({
      go:true,
      grades:[]
  })
  
  useEffect(function () {
      (async function() {
          const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+posid
          ).then((response) => {
              let restriction = response.data.restriction.filter(value=>value.action==='matchBarCodeToItem')
              if(restriction.length>0){
                  if(restriction[0].grade.length > 0){
                      setRestrictionAssociate({
                          go:false,
                          grades: restriction[0].grade.map(value=>value.id)
                      })
                  }
              }
              setSection(response.data)
          }).catch((error) => {
              if(error=='end_session') return;
              swal.fire(t('unknowError'), "", "error");
          })
      })()
  }, [])

  useEffect(function () {
      (async function() {
          const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/pos/get_one_caisse_ident/"+idsLocalStorage[1]
          ).then((response) => {
              setCaisseIDentName(response.data.data.code)
          }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
              messageError='unknowError'
            }
            else if(error.response.status==403){
              messageError='forbiddenGeneral'
            }
            else {
              messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
          })
      })()
  }, [])
  
  return [caisseIdentName,restrictionAssociate,setRestrictionAssociate,section]
}

const FooterPos = (props) =>{
    const t = useTranslate();
    const { peripheriqueParam } = useContext(PeripheriqueContext)
    const [caisseIdentName,restrictionAssociate,setRestrictionAssociate,section] = useLoad(props.idsLocalStorage,props.posid)
    const [show,setShow]=useState(false)
    const [isFor,setIsFor]=useState(false)
    const [orderForm,setOrderForm]=useState(false)
    const [ticketCommande,setTicketCommande]=useState(false)
    const [lastSaleOrder,setLastSaleOrder]=useState(false)
    const [loadLastSaleOrder,setLoadLastCaleOrder]=useState(false)
    const [modalTitle,setModalTitle]=useState('')
    const [isRestriction,setIsRestriction]=useState(false) 
    const [savedTicketList,setSavedTicketList]=useState([])
    const [loadingSavedticket,setLoadingSavedticket]=useState(false)
    const [ticketEnCours,setTicketEnCours]=useState({
      message:false,
      modal:false
    }) 

    const getLastSale = async () =>{//l'appel à l'api pour obtenir un ticket sur Pos.jsx sera relancé 
      if(props.cookies.last_ticket){
        if(props.cookies.current_ticket){
          setTicketEnCours({...ticketEnCours, modal:true})
          setModalTitle(t('lastSale'))
        }else{
          props.setLoadingLastTicket(true)
          setTicketEnCours({...ticketEnCours, modal:false})
          props.setCookie("current_ticket",props.cookies.last_ticket,{ path: "/", expires: props.expire_date }) 
          props.setIsLastTicket(true)
          props.setRelanceFocus(Date.now())
        }
      }else{
        swal.fire(t('noPreviousSale'), "", "error"
        ).then((result) => {
          if (result.isConfirmed) {
            props.setRelanceFocus(Date.now())
          } else{
            props.setRelanceFocus(Date.now())
          }
        })
        props.setLoadingLastTicket(false)
      }
    }
    
    const getLastDevis = async () =>{//l'appel à l'api pour obtenir un ticket sur Pos.jsx sera relancé 
      if(props.cookies.last_quotation){
        if(props.cookies.current_ticket){
          setTicketEnCours({...ticketEnCours, modal:true})
          setModalTitle(t('lastQuotation'))
        }else{
          props.setLoadingLastTicket(true)
          setTicketEnCours({...ticketEnCours, modal:false})
          props.setCookie("current_ticket",props.cookies.last_quotation,{ path: "/", expires: props.expire_date }) 
          props.setIsLastTicket(true)
          props.setRelanceFocus(Date.now())
        }
      }else{
        swal.fire(t('noPreviousDevis'), "", "error"
        ).then((result) => {
          if (result.isConfirmed) {
            props.setRelanceFocus(Date.now())
          } else{
            props.setRelanceFocus(Date.now())
          }
        })
        props.setLoadingLastTicket(false)
      }
    }

    const getAllSavedTicket = async () => {
      setLoadingSavedticket(true)
      const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/saved_ticket/get_all_in_section/"+props.posid
      ).then((response) => {
          if(!response.data.valid){
              swal.fire(t('unknowError'), "", "error");
          }
          else{   
              setSavedTicketList(response.data.datas)
          }
      }).catch((error) => {
          if(error=='end_session') return;
          swal.fire(t('unknowError'), "", "error");
      })
      setLoadingSavedticket(false)
    };

    const handleClick = (module) =>{
        setIsFor(module)
        if(module!='caisseIdTag')setShow(true)
        //Si un ticket est en cours, il ne faut pas qu'on puisse récupérer une commande client ou la dernière vente car cela peut créer des erreurs sur le fond de caisse 
        if(module=='lastSalesOrder' || module=='salesOrder' || module=='devisSearch'){
          if(props.cookies.current_ticket){
            setTicketEnCours({...ticketEnCours, message:true})
          }else{
            setTicketEnCours({...ticketEnCours, message:false})
          }
        }
        switch (module) {
          case 'pay':
              //ici on affiche le total, on a donc besoin de renseigner
              //le montant total, la raison et la devise 
              if(props.peripheriqueParam && props.peripheriqueParam.afficheur && props.peripheriqueParam.afficheur.model=='aures_2_l'){
                // Si on est sur un afficheur simple 2 lignes, on envoie la commande pour afficher le total sur l'afficheur, car sur le type second ecran, total est affiché tout le temps
                props.affichageClient('',props.totalAchat,'','total',props.devise,t,props.peripheriqueParam)
              }
              setModalTitle(t('btnPay'))
          break;
          case 'lastSalesOrder':
              setModalTitle(t('salesOrder'))
              if(!props.cookies.current_ticket && props.cookies.last_sale_order){
                setLoadLastCaleOrder(true)
                const test = axios.get(process.env.REACT_APP_API_URL+"api/pos/commande/get_by_id/"+props.cookies.last_sale_order+'/'+props.posid
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }else{  
                        if(response.data.data.cancel==1 || response.data.data.ticket.status==1){
                          props.removeCookie('last_sale_order',{ path: "/"})
                          setLastSaleOrder(false)
    
                        }else{
                          setLastSaleOrder(response.data.data)
                        }
                        setLoadLastCaleOrder(false)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    setLoadLastCaleOrder(false)
                })
              }
          break;
          case 'saved':
              setModalTitle(t('receiptSaved'))
          break;
          case 'return':
              setModalTitle(t('itemReturn'))
          break;
          case 'giftcardcredit':
              setModalTitle(t('giftCardCredit'))
          break;
          case 'salesOrder':
              setModalTitle(t('salesOrder'))
          break;
          case 'caisseIdTag':
              imprimeCaisseIdent(props.peripheriqueParam,caisseIdentName,'pos',t)
          break;
          case 'itemCreation':
              setModalTitle(t('itemCreation'))
          break;
          case 'barCodeMatch':
              setModalTitle(t('matchBarCode'))
          break;
          case 'devisSearch':
            setModalTitle(t('devisSearch'))
          break;
        }
    }

    const closeModal = (module) =>{
      if(Object.keys(props.currentTicket).length!=0 && props.currentTicket.status==1 && module=='onCloseModal'){
        props.setCashierId(false);
        props.setClientId(false);
        props.setValueInputCashier('')  
        props.setValueInputClient('')
        props.setInvalidInput({
            searchCashier: true,
            searchClient: true
        })
        props.removeCookie('current_ticket',{ path: "/"})
        props.setCurrentTicket({})
        props.setDisabledPay(true)

        if(props.peripheriqueParam && props.peripheriqueParam.afficheur && props.peripheriqueParam.afficheur.model=='second_screen_display'){
          // Dans le cas où on utilise on afficheur client de type écran secondaire, on éfface tout ce qu'il y a sur l'afficheur
          props.affichageClient(false,false,false,'clear',false,false,props.peripheriqueParam)
        }
      }
      if((Object.keys(props.currentTicket).length!=0 && props.currentTicket.status==1 && module=='onCloseModal') || Object.keys(props.currentTicket).length==0) props.resetSearchRaccourci()
      if(isRestriction) setRestrictionAssociate({...restrictionAssociate,go:false})
      setOrderForm(false)
      setTicketCommande(false)
      props.setRelanceFocus(Date.now())
      setShow(false)
    }

    return(
        <>
        <Modal size="xl" show={show} onHide={() => closeModal('onCloseModal')}>
            <Modal.Header >
                <Modal.Title>{
                  <div>
                      {modalTitle}
                      <OpenCashDrawerBtn peripheriqueParam={peripheriqueParam}/>
                  </div>
                }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(isFor && isFor =='pay') ?
                  <Suspense fallback={<Loading></Loading>}>
                    <PaymentForm 
                      posid={props.posid}
                      currentTicket={props.currentTicket}
                      setCurrentTicket={props.setCurrentTicket}
                      totalAchat={props.totalAchat}
                      cookies={props.cookies}
                      clientId={props.clientId}
                      setCookie={props.setCookie}
                      expire_date={props.expire_date}
                      orderForm={orderForm}
                      setOrderForm={setOrderForm}
                      ticketCommande={ticketCommande}
                      setTicketCommande={setTicketCommande}
                      resetSale={props.resetSale}
                      setModalTitle={setModalTitle}
                      setShow={setShow}
                      actualShop={props.actualShop}
                      setRelanceCommande={props.setRelanceCommande}
                      allCommandes={props.allCommandes}
                      setAllCommandes={props.setAllCommandes}
                      modulePos={props.modulePos}
                      restrictionRemoveLinePayment={props.restrictionRemoveLinePayment}
                      setRestrictionRemoveLinePayment={props.setRestrictionRemoveLinePayment}
                      section={props.section}
                      pointsFidelite={props.pointsFidelite}
                      setPointsFidelite={props.setPointsFidelite}
                      clientFideliteInfo={props.clientFideliteInfo}
                      setClientFideliteInfo={props.setClientFideliteInfo}
                      setShowBtnFidelite={props.setShowBtnFidelite}
                      countItems={props.countItems}
                      seuilCashBack={props.seuilCashBack}
                    />
                  </Suspense>
                : 
                  (isFor && isFor =='return') ?
                    <Suspense fallback={<Loading></Loading>}>
                      <ReturnArticleModal 
                        posid={props.posid}
                        digit={props.digit}
                        devise={props.devise}

                        expire_date={props.expire_date}
                        cookies={props.cookies}
                        setCookie={props.setCookie}
                        setCurrentTicket={props.setCurrentTicket}
                        clientId={props.clientId}
                        cashierId={props.cashierId}
                        affichageClient={props.affichageClient}
                        peripheriqueParam={props.peripheriqueParam}
                      />
                    </Suspense>
                  :
                    (isFor && isFor =='giftcardcredit') ?
                      <Suspense fallback={<Loading></Loading>}>
                        <CreditGiftCardModal 
                          posid={props.posid}
                          digit={props.digit}
                          devise={props.devise}
                        />
                      </Suspense>
                    :
                      (isFor && isFor =='salesOrder') ?
                        <Suspense fallback={<Loading></Loading>}>
                          <SearchSalesOrder   
                            posid={props.posid}
                            digit={props.digit}
                            devise={props.devise}
                            expire_date={props.expire_date}
                            cookies={props.cookies}
                            setCookie={props.setCookie}
                            removeCookie={props.removeCookie}
                            resetSale={props.resetSale}
                            closeModal={closeModal}
                            setIsSalesOrder={props.setIsSalesOrder}
                            setModalTitle={setModalTitle}
                            setShow={setShow}
                            ticketEnCours={ticketEnCours}
                          />
                        </Suspense>
                      :
                        (isFor && isFor =='itemCreation') ?
                          <Suspense fallback={<Loading></Loading>}>
                            <CreateItemFormPos   
                              setShow={setShow}
                              expire_date={props.expire_date}
                              cookies={props.cookies}
                              setCookie={props.setCookie}
                              clientId={props.clientId}
                              cashierId={props.cashierId}
                              posid={props.posid}
                              setCurrentTicket={props.setCurrentTicket}
                              setRelanceFocus={props.setRelanceFocus}
                              affichageClient={props.affichageClient}
                              actual_shop={props.actualShop.code}
                              peripheriqueParam={props.peripheriqueParam}
                              devise={props.devise}
                            />
                          </Suspense>
                      :
                        (isFor && isFor=='lastSalesOrder') ?
                          (loadLastSaleOrder) ?
                            <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm mt-2"></div></span>
                          :
                            (lastSaleOrder) ?
                                <Suspense fallback={<Loading></Loading>}>
                                  <OrderFormNext
                                    ticketCommande={lastSaleOrder}
                                    setTicketCommande={setLastSaleOrder}
                                    posid={props.posid}
                                    digit={props.digit}
                                    devise={props.devise}
                                    from='lastSaleOrder'
                                    expire_date={props.expire_date}
                                    cookies={props.cookies}
                                    setCookie={props.setCookie}
                                    removeCookie={props.removeCookie}
                                    resetSale={props.resetSale}
                                    closeModal={closeModal}
                                    setIsSalesOrder={props.setIsSalesOrder}
                                    setModalTitle={setModalTitle}
                                    setShow={setShow}
                                  />
                                </Suspense>
                              :
                                (ticketEnCours.message) ?
                                  <Suspense fallback={<Loading></Loading>}>
                                    <AlertCurrentReceipt /> 
                                  </Suspense>
                                :
                                  false
                        :
                          (isFor && isFor=='barCodeMatch') ?
                            <Suspense fallback={<Loading></Loading>}>
                              <MatchBarCodeForm
                                restrictionAssociate={restrictionAssociate}
                                setRestrictionAssociate={setRestrictionAssociate}
                                section={section}
                                posid={props.posid}
                                isRestriction={isRestriction}
                                setIsRestriction={setIsRestriction}
                              />
                            </Suspense>
                        :
                          (isFor && isFor=='devisSearch') ?
                            <Suspense fallback={<Loading></Loading>}>
                              <SearchDevisModal
                                expire_date={props.expire_date}
                                setCookie={props.setCookie}
                                closeModal={closeModal}
                                ticketEnCours={ticketEnCours}
                              />
                            </Suspense>
                          :
                            <Suspense fallback={<Loading></Loading>}>
                              <SavedTicketModal 
                                cookies={props.cookies}
                                digit={props.digit}
                                devise={props.devise}
                                saveTicket={props.saveTicket}
                                setCookie={props.setCookie}
                                setCurrentTicket={props.setCurrentTicket}
                                posid={props.posid}
                                expire_date={props.expire_date}
                                closeModal={closeModal}
                                removeCookie={props.removeCookie}
                                savedTicketList={savedTicketList}
                                setSavedTicketList={setSavedTicketList}
                              />
                            </Suspense>
                }
            </Modal.Body>
        </Modal>
        <Modal size="xl" show={ticketEnCours.modal} onHide={() =>  setTicketEnCours({...ticketEnCours, modal:false})}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AlertCurrentReceipt /> 
            </Modal.Body>
        </Modal>
        <Fragment>
          <div className="flex-grow-1"></div>
          <div id="footerPos">
            <div className="row">
              <div className="col-3">
                <h2 className="mb-2 totalPos">Total: <strong className='totalPos2'>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.totalAchat} />}</strong></h2>
                <h3 className="mb-0 dontRemise">{t('dontRemise')+':'} <strong className='totalPos2'>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.totalRemise} />}</strong></h3>
              </div>
              <div className="col-2">
                <p className="mt-0 mb-0"><strong>Nbr {t('items')}: <span>{props.countItems}</span></strong></p>
                <p className="mt-0 mb-0"><strong>Nbr {t('itemReturn')}: <span>{props.countItemsReturn}</span></strong></p>
                <p className="mt-0 mb-0"><strong>Nbr {t('lines')}: <span>{props.countLines}</span></strong></p>
              </div>
              <div className="col-4 btnFooterPos">
                <button disabled={props.disabledPay} className="btn btn-success" onClick={()=>handleClick('pay')}>{t('btnPay')}</button>
                <DropdownButton
                  drop='up'
                  variant="info"
                  disabled={props.disabledCloudButton}
                  title={(props.loadingLastTicket) ? <div className="spinner-border spinner-border-sm"></div> : <FontAwesomeIcon className="fs-5" icon={faCloudUploadAlt} />}
                  onClick={()=>getAllSavedTicket()}
                >
                  <Dropdown.Item eventKey="1" onClick={()=>props.saveTicket(false)}>{t('btnSave')}</Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={()=>handleClick('saved')}>{t('receiptSaved')}&nbsp;&nbsp;<Badge pill className="badge-primary">{loadingSavedticket ? <span className="spinner-border spinner-border-sm" style={{height:'0.7em', width:'0.7em'}}></span> : savedTicketList.length}</Badge></Dropdown.Item>
                  <Dropdown.Item eventKey="3" onClick={()=>getLastSale()}>{t('lastSale')}</Dropdown.Item>
                  <MasterCloudGiftCardButton modulePos={props.modulePos} handleClick={handleClick}/>
                  <MasterCloudCommandeButton actualShop={props.actualShop} modulePos={props.modulePos} handleClick={handleClick}/>
                  <Dropdown.Item eventKey="10" onClick={()=>getLastDevis()}>{t('lastQuotation')}</Dropdown.Item>
                  <Dropdown.Item eventKey="11" onClick={()=>handleClick('devisSearch')}>{t('devisSearch')}</Dropdown.Item>
                  <MasterCloudCreateItem modulePos={props.modulePos} handleClick={handleClick}/>
                  {(caisseIdentName) ? <Dropdown.Item eventKey="8" onClick={()=>handleClick('caisseIdTag')}>{t('printCaisseIdent')}</Dropdown.Item> : false}
                  <Dropdown.Item eventKey="9" onClick={()=>handleClick('barCodeMatch')}>{t('matchBarCode')}</Dropdown.Item>
                </DropdownButton>
                <button className="btn btn-warning" onClick={()=>handleClick('return')}>{t('btnReturn')}</button>
                {(props.cookies.current_ticket) &&
                  <button className="btn btn-danger" onClick={()=>props.resetSale('footer')}>{(props.loadResetTicket) ? <div className="spinner-border spinner-border-sm"></div> : (props.isLastTicket) ? t('btnClose') : t('btnCancel')}</button>
                }
              </div>
              <div className="col-3">
                <ApercuPrixArticleBarre devise={props.devise} digit={props.digit} actualShop={props.actualShop}/>
              </div>
            </div>
          </div>
        </Fragment>
      </>
    )

}

export default FooterPos;